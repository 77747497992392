// src/pages/CustomerForm.js
import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

function CustomerForm() {
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    points: 0,
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Customer added:', customer);
    // You can later integrate this with a backend API
    setCustomer({ name: '', email: '', points: 0 });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Add New Customer
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={customer.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={customer.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Points"
        name="points"
        type="number"
        value={customer.points}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
        Add Customer
      </Button>
    </Box>
  );
}

export default CustomerForm;
