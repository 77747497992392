import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthService } from '../utils/Api'; // Use the hook for AuthService
import { useUIContext } from '../contexts/UIContext'; // Use the UI context for error and loading handling
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  OutlinedInput,
  Alert,
} from '@mui/material';

function ForgotPassword({ open, handleClose }) {
  // Email address state
  const [email, setEmail] = useState('');
  const { forgotPasswordAsync } = useAuthService(); // Use forgotPasswordAsync function
  const { setLoading, setError } = useUIContext(); // Access loading and error context
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmitForgetPassword = async () => {
    setLoading(true); // Start the loading indicator
    setError(''); // Clear any existing errors
    setSuccessMessage(''); // Clear previous success messages
    try {
      await forgotPasswordAsync(email);
      setSuccessMessage('Password reset email sent successfully!');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleSubmitForgetPassword();
        },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your email address, and we’ll send you a link to reset your password.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          placeholder="Email address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* Show success or error messages */}
        {successMessage && <Alert severity="success" sx={{ mt: 2 }}>{successMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmitForgetPassword}
          variant="contained"
          type="submit"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
