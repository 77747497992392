import axios from 'axios';
import { useUIContext } from '../contexts/UIContext';

// Use the API URL from environment variables (default to localhost in development)
const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5179/Api';
console.log(process.env);


// Create Axios instance
const Api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

// Token management utility
const TokenService = {
  getAccessToken: () => localStorage.getItem('accessToken'),
  getRefreshToken: () => localStorage.getItem('refreshToken'),
  setTokens: ({ accessToken, refreshToken }) => {
    if (accessToken) localStorage.setItem('accessToken', accessToken);
    if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
    Api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  },
  clearTokens: () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete Api.defaults.headers['Authorization'];
  },
};

const useAuthService = () => {
  const { setLoading, setError } = useUIContext();

  return {
    googleLoginAsync: async (accessToken) => {
      try {
        setLoading(true);
        const response = await Api.post('/users/google-login', { accessToken });
        TokenService.setTokens({
          accessToken: response.data.AccessToken,
          refreshToken: response.data.RefreshToken,
        });
        return response.data;
      } catch (error) {
        setError('Google login failed. Please try again.');
        throw error;
      } finally {
        setLoading(false);
      }
    },

    emailLoginAsync: async (email, password) => {
      try {
        setLoading(true);
        const response = await Api.post('/users/login', { email, password });
        TokenService.setTokens({
          accessToken: response.data.AccessToken,
          refreshToken: response.data.RefreshToken,
        });
        return response.data;
      } catch (error) {
        setError('Email login failed. Invalid credentials.');
        throw error;
      } finally {
        setLoading(false);
      }
    },

    signUpAsync: async ({ firstName, lastName, email, password }) => {
      try {
        setLoading(true);
        const response = await Api.post('/users', {
          firstName,
          lastName,
          email,
          password,
        });
        return response.data;
      } catch (error) {
        setError('Sign-up failed. Please try again.');
        throw error;
      } finally {
        setLoading(false);
      }
    },

    forgotPasswordAsync: async (email) => {
      try {
        setLoading(true);
        const response = await Api.get(`/users/refresh-password?email=${email}`);
        return response.data;
      } catch (error) {
        setError('Forgot password request failed.');
        throw error;
      } finally {
        setLoading(false);
      }
    },

    refreshTokenAsync: async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${BASE_URL}/users/refresh-token`, {
          accessToken: TokenService.getAccessToken(),
          refreshToken: TokenService.getRefreshToken(),
        });

        TokenService.setTokens({
          accessToken: response.data.AccessToken,
          refreshToken: response.data.RefreshToken,
        });

        return response.data.AccessToken;
      } catch (error) {
        setError('Failed to refresh token. Please log in again.');
        TokenService.clearTokens();
        throw error;
      } finally {
        setLoading(false);
      }
    },

    validateEmailToken: async (token) => {
      try {
        setLoading(true);
        const response = await Api.get(`/users/validate`, { params: { token } });
        return response.data;
      } catch (error) {
        setError('Email validation failed.');
        throw error;
      } finally {
        setLoading(false);
      }
    },
  };
};

// Response interceptor for handling token expiration
Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { setError } = useUIContext();
    if (error.response && error.response.status === 401) {
      try {
        const newAccessToken = await TokenService.refreshTokenAsync();
        if (newAccessToken) {
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return Api(error.config);
        }
      } catch (refreshError) {
        setError('Session expired. Please log in again.');
        return Promise.reject(refreshError);
      }
    }
    setError('An error occurred while processing your request.');
    return Promise.reject(error);
  }
);

export { Api, useAuthService, TokenService };
