// src/pages/auth/VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Alert, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Api } from '../../utils/Api';

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = searchParams.get('token'); // Extract the token from the URL query string
      if (!token) {
        setStatus('error');
        setMessage('Invalid or missing token.');
        return;
      }

      try {
        const response = await Api.get(`/users/validate?token=${token}`);
        if (response.data.AccessToken) {
          setStatus('success');
          setMessage('Your email has been successfully verified!');
        } else {
          setStatus('error');
          setMessage(response.data.message || 'Invalid or expired token.');
        }
      } catch (error) {
        setStatus('error');
        setMessage('An error occurred during verification. Please try again.');
      }
    };

    verifyToken();
  }, [searchParams]);

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 10, p: 3, textAlign: 'center' }}>
      {status === 'loading' && (
        <>
          <Typography variant="h5" component="h1" gutterBottom>
            Verifying Your Email...
          </Typography>
          <CircularProgress />
        </>
      )}
      {status === 'success' && (
        <>
          <Alert severity="success" sx={{ mb: 2 }}>
            {message}
          </Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/signin')}
          >
            Go to Sign In
          </Button>
        </>
      )}
      {status === 'error' && (
        <>
          <Alert severity="error" sx={{ mb: 2 }}>
            {message}
          </Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/signup')}
          >
            Back to Sign Up
          </Button>
        </>
      )}
    </Box>
  );
}

export default VerifyEmail;
