// src/pages/CustomerManagement.js
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import CustomerForm from './CustomerForm';

function CustomerManagement() {
  const [customers, setCustomers] = useState([
    { id: 1, name: 'John Doe', email: 'john@example.com', points: 150, rewards: 2 },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', points: 100, rewards: 1 },
  ]);

  const addCustomer = (newCustomer) => {
    setCustomers([...customers, { ...newCustomer, id: customers.length + 1, rewards: 0 }]);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Customer Management
      </Typography>

      <CustomerForm addCustomer={addCustomer} />

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="customer table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Rewards Redeemed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.points}</TableCell>
                <TableCell>{customer.rewards}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CustomerManagement;
