import React from 'react';

interface RewardiouLogoProps {
  height?: number;
  width?: number;
}

export default function RewardiouLogoFull({ height = 150, width = 150 }: RewardiouLogoProps) {
  return (
    <img 
      src="https://rewardiou.com/images/logo_long.svg" 
      alt="Rewardiou Logo" 
      style={{ height, width }}
    />
  );
}