import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Context
const UIContext = createContext();

// Hook to use UI Context
export const useUIContext = () => useContext(UIContext);

// Provider Component
export const UIProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Load state from localStorage when component mounts
  useEffect(() => {
    const savedState = localStorage.getItem('uiContextState');
    if (savedState) {
      const { loading, error } = JSON.parse(savedState);
      setLoading(loading);
      setError(error);
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('uiContextState', JSON.stringify({ loading, error }));
  }, [loading, error]);

  return (
    <UIContext.Provider value={{ loading, setLoading, error, setError }}>
      {children}
    </UIContext.Provider>
  );
};