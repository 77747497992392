// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UIProvider } from './contexts/UIContext'; // Import as UIProvider

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GoogleOAuthProvider clientId="548245571179-o8e3756ae2p62dae42a360n1hhumriol.apps.googleusercontent.com">
      <UIProvider> {/* Use the correct export name */}
        <App />
      </UIProvider>
    </GoogleOAuthProvider>
);

reportWebVitals();
