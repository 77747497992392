// src/pages/home/Home.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../../assets/images/logo.png'; // Path to your logo

function Home() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: 4,
        marginTop: 6,
        boxShadow: 3,
        maxWidth: 600,
        margin: 'auto',
      }}
    >
      {/* Hero Section */}
      <Box
        component="img"
        src={logo}
        alt="Rewardiou Logo"
        sx={{
          maxWidth: '150px',
          marginBottom: 3,
        }}
      />
      <Typography variant="h4" gutterBottom>
        Welcome to Rewardiou!
      </Typography>
      <Typography variant="body1">
        Simplify customer loyalty management with personalized rewards. Manage customers, track engagement, and grow relationships with ease.
      </Typography>
    </Box>
  );
}

export default Home;
