// src/pages/auth/SignUp.js
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Link, Alert } from '@mui/material';
import { useAuthService } from '../../utils/Api'; // Use the correct import path for `useAuthService`
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { signUpAsync } = useAuthService(); // Use `useAuthService`

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      await signUpAsync({ firstName, lastName, email, password }); // Use `signUpAsync` from `useAuthService`
      setSuccessMessage('Registration successful! Please check your email to verify your account.');
      // Optionally redirect after a delay
      setTimeout(() => navigate('/signin'), 3000);
    } catch (error) {
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError('Registration failed. Please try again.');
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 10, p: 3, boxShadow: 3 }}>
      <Typography component="h1" variant="h5" align="center">
        Sign Up
      </Typography>
      <Box component="form" onSubmit={handleSignUp} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {successMessage}
          </Alert>
        )}

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
          Sign Up
        </Button>
      </Box>
      <Typography variant="body2" align="center">
        Already have an account?{' '}
        <Link
          href="/signin"
          onClick={(e) => {
            e.preventDefault();
            navigate('/signin');
          }}
        >
          Sign in
        </Link>
      </Typography>
    </Box>
  );
}

export default SignUp;
