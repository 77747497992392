// src/pages/auth/ValidateEmail.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Alert, CircularProgress, Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthService } from '../../utils/Api';

function ValidateEmail() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading'); // 'loading', 'success', or 'error'
  const { validateEmailToken } = useAuthService(); // Use `useAuthService`
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = searchParams.get('token');
      if (token) {
        try {
          await validateEmailToken(token);
          setStatus('success');
        } catch (error) {
          setStatus('error');
        }
      } else {
        setStatus('error'); // No token provided
      }
    };

    validateToken();
  }, [searchParams, validateEmailToken]); // Add `searchParams` and `validateEmailToken` as dependencies

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        mt: 10,
        p: 3,
        boxShadow: 3,
        textAlign: 'center',
      }}
    >
      {status === 'loading' && (
        <>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Validating your email, please wait...
          </Typography>
        </>
      )}

      {status === 'success' && (
        <>
          <Alert severity="success">Email verified successfully! You can now log in.</Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/signin')}
          >
            Go to Sign In
          </Button>
        </>
      )}

      {status === 'error' && (
        <>
          <Alert severity="error">Email verification failed. The token may be invalid or expired.</Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/signup')}
          >
            Sign Up Again
          </Button>
        </>
      )}
    </Box>
  );
}

export default ValidateEmail;
