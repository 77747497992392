import React from 'react';

interface RewardiouLogoProps {
  height?: number;
  width?: number;
}

export default function RewardiouLogo({ height = 50, width = 50 }: RewardiouLogoProps) {
  return (
    <img 
      src="https://rewardiou.com/images/short-logo.png" 
      alt="Rewardiou Logo" 
      style={{ height, width }}
    />
  );
}