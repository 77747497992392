// src/pages/auth/SignIn.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
  Link,
  Divider,
  Alert,
} from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleIcon } from '../../components/CustomIcons';
import ForgotPassword from '../../components/ForgotPassword';
import { useAuthService } from '../../utils/Api'; // Correct import
import { useNavigate } from 'react-router-dom';

function SignIn({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember Me"
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { googleLoginAsync, emailLoginAsync } = useAuthService(); // Use `useAuthService`
  const navigate = useNavigate();

  // Google Login Success Handler
  const handleGoogleLoginSuccess = async (response) => {
    try {
      const backendResponse = await googleLoginAsync(response.credential);

      // Store tokens based on "Remember Me" state
      if (rememberMe) {
        localStorage.setItem('accessToken', backendResponse.accessToken);
        localStorage.setItem('refreshToken', backendResponse.refreshToken);
      } else {
        sessionStorage.setItem('accessToken', backendResponse.accessToken);
        sessionStorage.setItem('refreshToken', backendResponse.refreshToken);
      }

      onLoginSuccess(); // Notify App component of successful login
      setSuccess('Successfully signed in with Google!');
      setTimeout(() => navigate('/home'), 3000); // Redirect to the home page
    } catch (error) {
      setError('Google login failed. Please try again.');
    }
  };

  const handleGoogleLoginError = () => {
    setError('Google login failed. Please try again.');
  };

  // Standard Login Handler
  const handleStandardLogin = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const backendResponse = await emailLoginAsync(email, password);

      // Store tokens based on "Remember Me" state
      if (rememberMe) {
        localStorage.setItem('accessToken', backendResponse.accessToken);
        localStorage.setItem('refreshToken', backendResponse.refreshToken);
      } else {
        sessionStorage.setItem('accessToken', backendResponse.accessToken);
        sessionStorage.setItem('refreshToken', backendResponse.refreshToken);
      }

      onLoginSuccess();
      setSuccess('Successfully signed in!');
      setTimeout(() => navigate('/home'), 3000); // Redirect to the home page
    } catch (error) {
      setError('Invalid email or password. Please try again.');
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 10, p: 3, boxShadow: 3 }}>
      <CssBaseline />
      <Typography component="h1" variant="h5" align="center">
        Sign In
      </Typography>
      <Box component="form" onSubmit={handleStandardLogin} sx={{ mt: 1 }}>
        <FormLabel>Email</FormLabel>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormLabel>Password</FormLabel>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
        {/* "Remember Me" Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              value="remember"
              color="primary"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)} // Update state
            />
          }
          label="Remember me"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
          Sign In
        </Button>
        <Divider>or</Divider>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
            prompt="select_account" // Forces account selection every time
            render={(renderProps) => (
              <Button
                fullWidth
                variant="outlined"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                startIcon={<GoogleIcon />}
                sx={{ mt: 2 }}
              >
                Sign in with Google
              </Button>
            )}
          />
        </Box>
        <Link
          href="#"
          onClick={() => setOpenForgotPassword(true)}
          variant="body2"
          sx={{ display: 'block', mt: 2, textAlign: 'center' }}
        >
          Forgot password?
        </Link>
        <ForgotPassword open={openForgotPassword} handleClose={() => setOpenForgotPassword(false)} />
      </Box>
      <Box textAlign="center" sx={{ mt: 2 }}>
        <Typography variant="body2">
          Don't have an account?{' '}
          <Link
            href="/signup"
            onClick={(e) => {
              e.preventDefault();
              navigate('/signup');
            }}
          >
            Sign up
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default SignIn;