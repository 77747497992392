// src/App.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, Drawer, List, ListItem } from '@mui/material'; // Removed ListItemText
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import CustomerManagement from './pages/customers/CustomerManagement';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import VerifyEmail from './pages/auth/VerifyEmail';
import ChangePassword from './pages/auth/ChangePassword';
import Home from './pages/home/Home';
import ValidateEmail from './pages/auth/ValidateEmail';
import { UIProvider } from './contexts/UIContext'; // Import the UI context provider
import LoadingOverlay from './components/LoadingOverlay'; // Import the loading overlay
import ErrorSnackbar from './components/ErrorSnackbar'; // Import the error snackbar
import MarketingPage from './pages/home/MarketingPage';

const drawerWidth = 240;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsAuthenticated(!!token);
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    document.cookie = "G_AUTHUSER_H=-1; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "G_AUTHUSER_C=-1; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    window.location.href = '/'; // Redirect to Home
  };

  return (
    <UIProvider> {/* Wrap the app with the UI context provider */}
      <LoadingOverlay /> {/* Global loading overlay */}
      <ErrorSnackbar /> {/* Global error snackbar */}
      <Router>
          {isAuthenticated && <Sidebar />}
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              {/* <Toolbar>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {!isAuthenticated ? (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        href="/signin"
                      >
                        Sign In
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        href="/signup"
                      >
                        Sign Up
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="error"
                      variant="contained"
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  )}
                </Box>
              </Toolbar> */}

            <Toolbar />

            <Routes>
              <Route
                path="/"
                element={isAuthenticated ? <Navigate to="/home" replace /> : <MarketingPage />}
              />
              <Route
                path="/signin"
                element={isAuthenticated ? <Navigate to="/home" replace /> : <SignIn onLoginSuccess={handleLoginSuccess} />}
              />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/customers"
                element={isAuthenticated ? <CustomerManagement /> : <Navigate to="/signin" replace />}
              />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/validate-email" element={<ValidateEmail />} />
              <Route path="/home" element={<Home />} />
            </Routes>
          </Box>
      </Router>
    </UIProvider>
  );
}

// Sidebar Component
function Sidebar() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto', padding: 2 }}>
        <List>
          <ListItem>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleNavigation('/home')}
            >
              Home
            </Button>
          </ListItem>
          <ListItem>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleNavigation('/customers')}
            >
              Customer Management
            </Button>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

export default App;
